@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333342;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes dash {
  from {
    stroke-dashoffset: 800;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.path {
  stroke-dasharray: 500;
  animation: dash 1s linear;
}

.markdown-rendered > * {
  margin-top: 1em;
  margin-bottom: 1em;
}

.markdown-rendered h1 {
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  color: #333;
}

.markdown-rendered h3 {
  font-size: 1.2em;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0.67em;
  color: #333;
}

.markdown-rendered a {
  text-decoration: underline;
  cursor: pointer;
  color: #2463eb;
}
.about a {
  text-decoration: underline;
  cursor: pointer;
  color: #2463eb;
}
